import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
	startEvent: "DOMContentLoaded",
	initClassName: "aos-init",
	animatedClassName: "aos-animate",
	useClassNames: false,
	disableMutationObserver: false,
	duration: 500,
	offset: 0,
	easing: "ease",
	once: true,
});
AOS.refresh(true);

const header = document.getElementsByClassName(`header`)[0];
const mobileHeader = document.querySelector(".mobile-header");
const burger = document.querySelector(".menu-toggle");
const headerCloseWrapper = document.querySelector(".header-close-wrapper");

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

// Hide and show Header when scroll up (on Mobile)
let prevScrollPos = window.pageYOffset;

function ifDesktop() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);

	if (window.innerWidth > 1200) {
		window.removeEventListener("scroll", fixedHeader, false);
		header.style.top = 0;
	} else {
		window.addEventListener("scroll", fixedHeader, false);
	}
}

function fixedHeader() {
	if (window.matchMedia("(max-width: 1200px)").matches) {
		const currentScrollPos = window.pageYOffset;
		const headerHeight = header.scrollHeight;

		if (window.scrollY > 0) {
			if (prevScrollPos > currentScrollPos) {
				header.style.top = 0;
				header.classList.add("scrolled");
			} else {
				header.style.top = `-${headerHeight + 3}px`;
			}
		}

		if (window.scrollY === 0) {
			header.classList.remove("scrolled");
		}

		prevScrollPos = currentScrollPos;
	}
}

window.addEventListener("resize", ifDesktop, false);
window.addEventListener("scroll", fixedHeader, false);

if (window.scrollY !== 0) {
	header.classList.add("scrolled");
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
	document.documentElement
).getPropertyValue("--mobile-menu-start-point");

let isMobileMenuEnable =
	window.innerWidth <= mobileMenuStartPoint || mobileHeader;

// toggle menu handler
function menuToggle() {
	header.classList.toggle("active");
	mobileHeader.classList.toggle("active");
	burger.classList.toggle("active");
	headerCloseWrapper.classList.toggle("active");

	// LockScroll when burger open and enable when closed and enable scroll on menu
	if (scrollLock.getScrollState()) {
		scrollLock.disablePageScroll(document.querySelector(".mobile-navbar"));
		scrollLock.addScrollableSelector(".simplebar-content-wrapper");
	} else {
		scrollLock.clearQueueScrollLocks();
		scrollLock.enablePageScroll();
	}

}

//menu update function
function updateMenu() {
	isMobileMenuEnable =
		window.innerWidth <= mobileMenuStartPoint || mobileHeader;

	if (!isMobileMenuEnable) {
		headerCloseWrapper.classList.remove("active");
		document.querySelector(".navbar-nav").classList.remove("active");
		burger.classList.remove("active");

		// LockScroll when burger open and enable when closed
		scrollLock.enablePageScroll();
	}
}

window.addEventListener("resize", updateMenu);
window.addEventListener("orientationchange", updateMenu);

// end of toggle menu handler

burger.onclick = () => menuToggle(); //menu toggles
headerCloseWrapper.onclick = () => menuToggle(); //menu toggles

// inline style clearing
const contentElement = document.querySelector(".job-content");
if (contentElement) {
	const inlineStyleList = contentElement.querySelectorAll("style");

	inlineStyleList.forEach((item) => item.remove());

	const innerElementList = contentElement.querySelectorAll("*");

	innerElementList.forEach((item) => {
		item.removeAttribute("class");
		item.removeAttribute("style");
	});
}

// Toggle class
window.toggleCheckbox = function (item) {
	var input = item.querySelector("input");
	if (input.checked) {
		item.classList.add("active");
	} else {
		item.classList.remove("active");
	}
};

// Close subscribe popup
const subscribePopup = document.querySelector(".subscribe-popup");
if (subscribePopup) {
	const closeBtn = subscribePopup.querySelector(".close-btn");
	
	closeBtn.addEventListener("click", () => subscribePopup.style.display = 'none')
}

// Tesla style menu
const navbar = header.querySelector(`.navbar-nav .menu`);
if (navbar) {
	const navbarElements = navbar.querySelectorAll(`a`);
	const spanElement = navbar.querySelector(`span`);
	const activeMenuElement = navbar.querySelector(`a.active`);

	function backgroundMenuPositionFunc(targetElement, flagMouseEnter) {
		const navbarPosition = navbar.getBoundingClientRect();
		const elementPosition = targetElement.getBoundingClientRect();

		let spanPositionLeftStart = elementPosition.left - navbarPosition.left;
		let spanWidthStart = elementPosition.width;

		if (flagMouseEnter) {
			spanElement.style.setProperty(
				"--span-transition",
				`0.5s cubic-bezier(0.75, 0, 0, 1)`
			);
		} else {
			spanElement.style.setProperty(
				"--span-transition",
				`opacity 0.5s ease, visibility 0s 0s`
			);
		}
		spanElement.style.setProperty("--width-span", `${spanWidthStart}px`);
		spanElement.style.setProperty(
			"--left-position-span",
			`${spanPositionLeftStart}px`
		);
	}

	if (activeMenuElement) {
		backgroundMenuPositionFunc(activeMenuElement, true);
		spanElement.classList.add("active");

		navbarElements.forEach((elem) => {
			elem.addEventListener("mouseenter", function (e) {
				backgroundMenuPositionFunc(e.target, true);

			});

			navbar.addEventListener("mouseleave", function (e) {
				backgroundMenuPositionFunc(activeMenuElement, true);
			});
		});
	} else {
		let flagMouseEnter = false;

		navbarElements.forEach((elem) => {
			elem.addEventListener("mouseenter", function (e) {
				backgroundMenuPositionFunc(e.target, flagMouseEnter);

				if (e.target.matches(".dropdown-link .menu-link")) {
					spanElement.classList.remove("active");
				} else {
					spanElement.classList.add("active");
				}

				flagMouseEnter = true;
			});
		});

		navbar.addEventListener("mouseleave", function (e) {
			spanElement.classList.remove("active");
			flagMouseEnter = false;
			spanElement.style.setProperty(
				"--span-transition",
				`opacity 0.5s ease, visibility 0s 0.5s`
			);
		});
	}
}

// Tables in content element
const tables = document.querySelectorAll("table");
if (tables.length) {
	tables.forEach((item) => {
		const wrapper = document.createElement("div");
		wrapper.classList.add("table-box");

		item.parentElement.insertBefore(wrapper, item);
		wrapper.appendChild(item);

		new SimpleBar(item.parentElement, { autoHide: false });
	});
}

// Accordion filters
function accordionSetup(parentsSelector, triggerSelector, childrenListSelector) {
	if (parentsSelector) {
		const accordions = document.querySelectorAll(parentsSelector);

		if (accordions.length) {
			accordions.forEach((accordion) => {
				const trigger = accordion.querySelector(triggerSelector);
				const childrenList = accordion.querySelector(childrenListSelector);

				trigger.addEventListener("click", () => {
					accordion.classList.toggle("open");

					if (accordion.classList.contains("open")) {
						childrenList.style.maxHeight = childrenList.scrollHeight + "px";
					} else {
						childrenList.style.maxHeight = 0;
					}
				});
			});
		}
	}
}

accordionSetup(".parent-item", ".checkbox-trigger", ".children-list")

const parentFilters = document.querySelector(".filters-locations");
if (parentFilters) parentFilters.classList.add("has-parent");

accordionSetup(".blog-filters__item", ".blog-filters__item-title", ".blog-filters__list")

accordionSetup(".mobile-header .dropdown-link", ".trigger", ".dropdown-menu")

// Parallax effect
function parallaxEffect(elements) {
	// Magic happens here
	function parallax(e) {
		let width = window.innerWidth / 2;
		let height = window.innerHeight / 2;

		let mouseX = e.clientX;
		let mouseY = e.clientY;

		let firstPos = `${50 - (mouseX - width) * 0.1}%`;
		let secondPos = `${50 - (mouseY - height) * 0.1}%`;

		if (elements.length) {
			elements.forEach((item, i) => {
				if (i % 2 === 0) {
					item.style.transform = `translate(${firstPos}, ${secondPos})`;
				} else {
					item.style.transform = `translate(-${firstPos}, -${secondPos})`;
				}
			});
		}
	}

	document.addEventListener("mousemove", parallax);

	if (window.matchMedia("(max-width: 1024px)").matches) {
		document.removeEventListener("mousemove", parallax);
	}

	window.onresize = function () {
		if (window.innerWidth < 1024) {
			document.removeEventListener("mousemove", parallax);
		}
	};
}

const parallaxItems = document.querySelectorAll(".parallax");
parallaxEffect(parallaxItems);

// Custom Select
const selectArray = Array.from(document.getElementsByClassName("select2"));
if (selectArray.length > 0) {
	selectArray.forEach((elem) => {
		new Choices(elem, {
			searchEnabled: false,
		});
	});
}

// Job description
const jobSection = document.querySelector(".job-single");
if (jobSection) {
	const applyBtn = jobSection.querySelectorAll(".apply-btn")[1];
	const jobDescription = jobSection.querySelector(".job-content");
	const descriptionItems = Array.from(jobDescription.children);
	const arrayOfLength = descriptionItems.map((item) => item.textContent.length);

	// Remove second apply button
	if (arrayOfLength.reduce((acc, curr) => acc + curr) > 700) {
		applyBtn.classList.remove("hidden");
	}
}

// Scroll to element
window.scrollToBlock = function () {
	const filtersBlock = document.getElementById("jobs_filters");
	const inputSearch = document.getElementById("keywords");

	if (window.innerWidth <= 901) {
		filtersBlock.scrollIntoView();
	} else {
		inputSearch.scrollIntoView();
	}
};

/* Tabs */
const tabsSection = document.querySelector(".tabs-wrap");
if (tabsSection) {
	const tabs = tabsSection.querySelectorAll(".tab-header"); // Tabs list
	const tabsHeader = tabsSection.querySelector(".tabs-list"); // Parent of tabs list
	const tabsContent = tabsSection.querySelectorAll(".tab-content"); // Content List

	const tabsBlogTitlesArr = document.querySelectorAll(".blog-section__title"); // Content List

	// Hide items Function
	function hideItems() {
		tabsContent.forEach((item) => {
			item.classList.remove("active");
		});

		tabs.forEach((item) => {
			item.classList.remove("active");
			item.querySelector("input").checked = false;
		});

		tabsBlogTitlesArr.forEach((item) => {
			item.classList.add("hidden");
		});
	}

	// Show items Function
	function showItems(i = 0) {
		tabsContent[i]?.classList.add("active");
		tabs[i]?.classList.add("active");

		tabsBlogTitlesArr[i]?.classList.remove("hidden");

		// Input checked status
		tabs[i].querySelector("input").checked = true;
		load('blogs/search', 'form:#filters', 'form:#industries_filter');
	}

	hideItems();
	showItems();

	// Event delegation
	tabsHeader.addEventListener("click", (event) => {
		const target = event.target;

		if (target && target.classList.contains("tab-header")) {
			tabs.forEach((item, i) => {
				if (target == item) {
					hideItems();
					showItems(i);
				}
			});
		}
	});
}

const podcastFilters = document.querySelector(".podcasts-tabs__list");
if (podcastFilters) {
	const buttons = podcastFilters.querySelectorAll(".podcasts-tabs__list-item");

	podcastFilters.addEventListener("click", (e) => {
		const target = e.target;

		if (target && target.classList.contains("podcasts-tabs__list-item")) {
			buttons.forEach((btn, i) => {
				const input = btn.querySelector("input");

				input.checked = false;
				btn.classList.remove("active")

				if (target == btn) {
					input.checked = true;
					btn.classList.add("active");
					load('blogs/search', 'only_podcast=true', 'form:#podcasts_filter');
				}
			});
		}
	})
}


/* ----------- Sliders ----------- */

// Testimonials sliders
const testimonialsSwiper = document.querySelector(
	".testimonials-content-slider"
);
if (testimonialsSwiper) {
	// Logo Testimonials Slider
	const logoSwiper = new Swiper(".logo-swiper", {
		slidesPerView: 1,
		allowTouchMove: false,
		// autoHeight: true,
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
	});

	// Testimonials Slider
	const testimonialsSlides = testimonialsSwiper.querySelectorAll(
		".testimonials-swiper .swiper-slide"
	);
	const testimonialsSlider = new Swiper(".testimonials-swiper", {
		slidesPerView: 1,

		// autoplay: {
		// 	delay: 3500,
		// 	disableOnInteraction: false,
		// 	pauseOnMouseEnter: true,
		// },

		thumbs: {
			swiper: logoSwiper,
		},

		// Pagination
		navigation: {
			nextEl: ".swiper-btn-next.testimonial-btn",
			prevEl: ".swiper-btn-prev.testimonial-btn",
		},
	});

	// Circular Slider
	const circleSlider = document.querySelector(".testimonials-circular-slider");
	if (circleSlider) {
		const circleItems = Array.from(
			circleSlider.querySelectorAll(".slides-holder__item")
		);
		// Hide items Function
		function hideItems() {
			circleItems.forEach((item) => {
				item.classList.remove("slides-holder__item_active");
			});
		}

		// Show items Function
		function showItems(i = 0) {
			circleItems[i].classList.add("slides-holder__item_active");
		}

		hideItems();
		showItems();

		// Event delegation
		circleSlider.addEventListener("click", (e) => {
			const target = e.target;

			if (target && target.classList.contains("slides-holder__item")) {
				circleItems.forEach((item, i) => {
					if (target == item) {
						hideItems();
						showItems(i);
						testimonialsSlider.slideTo(i);
					}
				});
			}
		});

		testimonialsSlider.on("slideChange", () => {
			hideItems();
			showItems(testimonialsSlider.activeIndex);
		});
	}

	scrollLock.enablePageScroll(
		testimonialsSwiper.querySelector(".testimonials-content")
	);

	SafariSwiper(testimonialsSlider);
	SafariSwiper(logoSwiper);
}

// Key people
const keyPeopleSection = document.querySelector(".key-people");
if (keyPeopleSection) {
	const keyPeopleSlides = keyPeopleSection.querySelectorAll(".swiper-slide");
	const keyPeopleSlider = new Swiper(".key-people-swiper", {
		slidesPerView: "auto",
		spaceBetween: 10,

		navigation: {
			nextEl: ".swiper-btn-next.key-people-btn",
			prevEl: ".swiper-btn-prev.key-people-btn",
		},

		effect: "creative",
		creativeEffect: {
			limitProgress: 8,
			prev: {
				translate: [0, 0, -200],
				opacity: 0,
			},
			next: {
				translate: ["calc(100% + 120px)", 0, 0],
				opacity: 1,
			},
		},

		breakpoints: {
			901: {
				spaceBetween: 50,
			},
			1281: {
				spaceBetween: 120,
			},
		},
	});

	SafariSwiper(keyPeopleSlider);

	if (keyPeopleSlides.length < 3 && window.innerWidth > 900) {
		keyPeopleSlider.disable();
	}

	if (keyPeopleSlides.length < 4 && window.innerWidth > 1300) {
		keyPeopleSlider.disable();
	}

	// window.addEventListener("resize", () => {
	// 	window.innerWidth < 900
	// 		? keyPeopleSlider.enable()
	// 		: keyPeopleSlider.disable();
	// });
}

// Sector services
const sectorServicesSection = document.querySelector(".sector-services");
if (sectorServicesSection) {
	const swiperNav = sectorServicesSection.querySelector(".swiper-buttons");
	const slides = sectorServicesSection.querySelectorAll(".swiper-slide");
	swiperNav.style.display = slides.length > 3 ? "flex" : "none";

	const sectorServicesSlider = new Swiper(".sector-services-swiper", {
		slidesPerView: "auto",
		spaceBetween: 20,
		grabCursor: true,

		navigation: {
			nextEl: ".swiper-btn-next.services-btn",
			prevEl: ".swiper-btn-prev.services-btn",
		},

		effect: "creative",
		creativeEffect: {
			limitProgress: 8,
			prev: {
				translate: [0, 0, -200],
				opacity: 0,
			},
			next: {
				translate: ["calc(100% + 20px)", 0, 0],
				opacity: 1,
			},
		},
	});

	SafariSwiper(sectorServicesSlider);
}

// Latest opportunities
const opportunitiesSection = document.querySelector(".latest-opportunities");
if (opportunitiesSection) {
	const opportunitiesSlides =
		opportunitiesSection.querySelectorAll(".swiper-slide");

	const opportunitiesSlider = new Swiper(".opportunities-swiper", {
		slidesPerView: 1,
		spaceBetween: 30,
		loop: opportunitiesSlides.length > 4,

		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},

		breakpoints: {
			551: {
				slidesPerView: "auto",
			},
		},

		navigation: {
			nextEl: ".swiper-btn-next.opportunity-btn",
			prevEl: ".swiper-btn-prev.opportunity-btn",
		},
	});

	if (opportunitiesSlides.length < 4) {
		opportunitiesSection.querySelector(".swiper-buttons").style.display =
			"none";
		opportunitiesSlider.autoplay.stop();
	}

	SafariSwiper(opportunitiesSlider);
}

// Related jobs
const relatedJobsSection = document.querySelector(".related-jobs");
if (relatedJobsSection) {
	const relatedJobsSlides =
		relatedJobsSection.querySelectorAll(".swiper-slide");

	const relatedJobsSlider = new Swiper(".related-jobs-swiper", {
		slidesPerView: 1,
		spaceBetween: 30,
		grabCursor: true,

		loop: relatedJobsSlides.length > 4,

		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},

		navigation: {
			nextEl: ".swiper-btn-next.related-job-btn",
			prevEl: ".swiper-btn-prev.related-job-btn",
		},

		breakpoints: {
			551: {
				slidesPerView: "auto",
			},
		},
	});

	SafariSwiper(relatedJobsSlider);
}

// no UI Slider on Job Search page
const doubleRangeSliderFunc = () => {
	const doubleRange = document.getElementsByClassName(
		"double-range-default"
	)[0];
	if (doubleRange) {
		const slider = doubleRange.querySelector("#double-range-default");
		const max = +slider.dataset.max;
		const min = +slider.dataset.min;
		const unit = slider.dataset?.unit || "£";
		const step = +slider.dataset.step;
		const inputsHidden = doubleRange.querySelectorAll(
			".double-range-hidden-input"
		);
		const inputInfoMin = doubleRange.querySelector(".double-range-info.min");
		const inputInfoMax = doubleRange.querySelector(".double-range-info.max");
		const startValueMin = +inputsHidden[0].value;
		const startValueMax = +inputsHidden[1].value;

		noUiSlider.create(slider, {
			start: [startValueMin, startValueMax],
			connect: true,
			margin: 10,
			step: step,
			range: {
				min: min,
				max: max,
			},
		});

		slider.noUiSlider.on("update", function (values, handle) {
			inputInfoMin.textContent = `${
				unit + Math.round(values[0]).toLocaleString("en-GB")
			}`;

			inputInfoMax.textContent = `${
				unit + Math.round(values[1]).toLocaleString("en-GB")
			}`;

			inputsHidden[handle].value = Math.round(values[handle]);
		});

		return slider.noUiSlider;
	}
};

window.doubleRangeSliderFunc = doubleRangeSliderFunc;

// Life section
const lifeSection = document.querySelector(".life-section");
if (lifeSection) {
	const reviewsSlides = lifeSection.querySelectorAll(".swiper-slide");
	const reviewsSlider = new Swiper(".team-reviews", {
		slidesPerView: "auto",
		spaceBetween: 30,
		centeredSlides: true,

		navigation: {
			nextEl: ".swiper-btn-next.careers-btn",
			prevEl: ".swiper-btn-prev.careers-btn",
		},

		breakpoints: {
			551: {
				centeredSlides: false,
				slidesPerView: "auto",
			},

			768: {
				slidesPerView: 2,
				centeredSlides: false,
			},

			1024: {
				slidesPerView: 3,
				centeredSlides: false,
			},

			1280: {
				slidesPerView: 4,
				centeredSlides: false,
			},

			1920: {
				slidesPerView: "auto",
				centeredSlides: false,
			},
		},
	});

	if (reviewsSlides.length <= 4 && window.innerWidth >= 1920) {
		reviewsSlider.disable();
	}

	SafariSwiper(reviewsSlider);
}
